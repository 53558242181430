import { createRouter, createWebHistory } from 'vue-router'
import Vue from 'vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home-clear',
      component: () => import('../views/home/Index.vue'),
    },
    
    {
      path: '/maria',
      name: 'maria-home',
      component: () => import('../views/home/Index.vue'),
    },
    {
      path: '/maria/:link',
      name: 'maria',
      component: () => import('../views/home/Index.vue'),
    },
    
    {
      path: '/consultation',
      name: 'consultation-home',
      component: () => import('../views/home/Index.vue')
    },
    {
      path: '/consultation/:link',
      name: 'consultation',
      component: () => import('../views/home/Index.vue')
    },
    
    {
      path: '/game',
      name: 'game-home',
      component: () => import('../views/home/Index.vue')
    },
    {
      path: '/game/:link',
      name: 'game',
      component: () => import('../views/home/Index.vue')
    },
    
    {
      path: '/breakfast',
      name: 'breakfast-home',
      component: () => import('../views/home/Index.vue')
    },
    {
      path: '/breakfast/:link',
      name: 'breakfast',
      component: () => import('../views/home/Index.vue')
    },
    
    {
      path: '/studio',
      name: 'studio-home',
      component: () => import('../views/home/Index.vue')
    },
    {
      path: '/studio/:link',
      name: 'studio',
      component: () => import('../views/home/Index.vue')
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: () => import('../views/agreement/Index.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('../views/privacy-policy/Index.vue')
    },
  ]
})

export default router