<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? content : `Eco-boss` }}</template>
  </metainfo>

  <RouterView/>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  setup() {
    useMeta({
      title: 'Eco-boss',
      htmlAttrs: { lang: 'ru', amp: true }
    })
  },
  name: 'App',
};
</script>